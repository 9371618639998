import React from 'react'
import './ImageText.css'

class ImageText extends React.Component {
    render () {
        return (
            <div className={"image-text image-align-" + this.props.imageAlign}>
                <div className="text-object">
                    <div className="title">{this.props.title}</div>
                    <div className="text" dangerouslySetInnerHTML={{__html: this.props.text}} />
                </div>
                <div className="image"><img src={this.props.image} alt={this.props.title} /></div>
            </div>
        )
    }
}

export default ImageText