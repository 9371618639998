import React from 'react';
import './Grid.css';

class Grid extends React.Component {
    render () {
        
        return (
            <div className="grid">
                {this.props.items.map((item) => (
                    <div key={item.title} className="grid-item">
                        <div className="title">{item.title}</div>
                        <div className="text">{item.text}</div>
                    </div>
                ))}
            </div>
        );
    }
}

export default Grid;
