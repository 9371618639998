import React from 'react';
import './App.css';

// components
import Header from './Header.js'
import Section from './Section.js'
import Grid from './Grid.js'
import ImageText from './ImageText.js'
import Footer from './Footer.js'
import tablet from './tablet.png'
import artlogic_database from './artlogic-database.png'
import spottinstyle from './spottinstyle.png'

// data
import content from './content.json'

class App extends React.Component {
    render () {
        
        const images = {
            tablet: tablet,
            'artlogic-database': artlogic_database,
            spottinstyle: spottinstyle
        }
        
        return (
            <div className="app">
                <Header name={content.name} about={content.about} icons={content.header.icons} />
                
                {content.sections.map((section) => (
                    <Section key={section.id} id={section.id} background={section.background} title={section.title}>
                        {section.content.map((component) => (
                            <div key={component.id} className="content-component">
                                {component.type === "text" ? <div className="text-block"><div dangerouslySetInnerHTML={{__html:component.text}} /><div className="icons"><a href="https://www.linkedin.com/in/robin-fowler-1922bb191" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin" /></a><a href="https://github.com/robin-fowler" target="_blank" rel="noopener noreferrer"><i className="fab fa-github" /></a></div></div> : ''}
                                {component.type === "grid" ? <Grid items={component.items}/> : '' }
                                {component.type === "image-and-text" ? <ImageText title={component.title} text={component.text} image={images[component.image]} imageAlign={component.imageAlign} /> : '' }
                            </div>
                        ))}
                    </Section>
                ))}
                
                <Footer text={content.footer.text} />
            </div>
        );
    }
}

export default App;