import React from 'react'
import './Header.css'
import portrait from './portrait-photo.jpg'

class Header extends React.Component {
    
    constructor (props) {
        super(props);
        this.iconClasses = this.props.icons || [];
        this.setIcons();
    }
    
    getRandomNumber (min, max) {
      return Math.random() * (max - min) + min;
    }
    
    getRandomPosition () {
        const top = this.getRandomNumber(10, 100);
        const left = this.getRandomNumber(3, 100);
        return {
            top: top,
            left: left
        }
    }
    
    getIconPosition () {
        
        const that = this;
        let position = this.getRandomPosition();
        
        if (!this.getIconPosition.i) {
            this.getIconPosition.i = 1;
        }
        
        this.icons.forEach((icon) => {
            
            const iconIsCentred = position.top > 20 && position.top < 90 && position.left > 40 && position.left < 60;
            const iconNearOthers = Math.abs(position.top - icon.position.top) < 15 && Math.abs(position.left - icon.position.left) < 15;
            
            this.getIconPosition.i++
            
            if (that.getIconPosition.i < 5000 && (iconIsCentred || iconNearOthers)) {
                position = that.getIconPosition();
            }
        });
        
        this.getIconPosition.i = 1;
        
        return position;
    }
    
    
    setIcons () {
        
        this.icons = [];
        
        this.iconClasses.forEach((icon_class) => {
            let icon = {
                class: icon_class
            };
            
            icon.position = this.getIconPosition();
            
            icon.css = {
                top: 'calc(' + icon.position.top + '% - 3rem)',
                left: 'calc(' + icon.position.left + '% - 3rem)'
            }
            
            icon.size = 'fa-3x';
            
            this.icons.push(icon);
        });
            
        return this.icons;
    }

    render () {
        
        return (
            <header className="app-header">
                <div className="icon-background">
                    {this.icons.map((icon) => <i key={icon.class} className={icon.class + ' ' + icon.size} style={icon.css}/>)}
                </div>
                <div className="img"><img src={portrait} alt="robin selfie"/></div>
            </header>
        )
    }
}

export default Header