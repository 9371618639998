import React from 'react'
import './Section.css'

class Section extends React.Component {
    render () {
        return (
            <section className={"section " + this.props.background + " " + this.props.id}>
                {this.props.title ? <h1>{this.props.title}</h1> : ''}
                {this.props.children}
            </section>
        )
    }
}

export default Section