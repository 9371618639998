import React from 'react'
import './Footer.css'

class Footer extends React.Component {
    render () {
        return (
            <footer className="footer"><span dangerouslySetInnerHTML={{__html:this.props.text}} /></footer>
        )
    }
}

export default Footer